<template>
  <b-card>
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
        :data="exportData"
        :columns="exportColumns"
        :file-name="'servolution'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        class="btn btn-primary"
      >
        Export
      </vue-excel-xlsx>
    </div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="rows"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPageDropdownEnabled: false,
        dropdownAllowAll: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field == 'name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.photo"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>
        <span
          v-else-if="props.column.field == 'first_option_audition_schedule'"
        >
          <span
            v-if="(props.row.first_option_audition_room && props.row.first_option_audition_schedule) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule)"
          >
            <li>Audisi: {{ moment(props.row.first_option_audition_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_audition_room }})</li>
            <li>Interview: {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})</li>
          </span>
          <span
            v-else-if="(props.row.first_option_audition_room && props.row.first_option_audition_schedule) && ((props.row.first_option_interview_room && !props.row.first_option_interview_schedule) || (!props.row.first_option_interview_room && props.row.first_option_interview_schedule) || (!props.row.first_option_interview_room && !props.row.first_option_interview_schedule))"
          >
            <li>Audisi: {{ moment(props.row.first_option_audition_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_audition_room }})</li>
            <li>Interview: Dihubungi PIC</li>
          </span>
          <span
            v-else-if="((props.row.first_option_audition_room && !props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && !props.row.first_option_audition_schedule)) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule && props.row.division_type === 2)"
          >
            {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})
          </span>
          <span
            v-else-if="((props.row.first_option_audition_room && !props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && props.row.first_option_audition_schedule) || (!props.row.first_option_audition_room && !props.row.first_option_audition_schedule)) && (props.row.first_option_interview_room && props.row.first_option_interview_schedule)"
          >
            <li>Audisi: Dihubungi PIC</li>
            <li>Interview: {{ moment(props.row.first_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.first_option_interview_room }})</li>
          </span>
          <span v-else>
            <li>Audisi: Dihubungi PIC</li>
            <li>Interview: Dihubungi PIC</li>
          </span>
        </span>
        <span
          v-else-if="props.column.field == 'second_option_interview_schedule' && props.row.second_option_ministry"
        >
          <span
            v-if="props.row.second_option_interview_room && props.row.second_option_interview_schedule"
          >
            {{ moment(props.row.second_option_interview_schedule).format("dddd, DD MMMM YYYY HH:mm") }} ({{ props.row.second_option_interview_room }})
          </span>
          <span
            v-else-if="!props.row.second_option_interview_room || !props.row.second_option_interview_schedule"
          >
            Dihubungi PIC
          </span>
        </span>
        <span
          v-else-if="props.column.field == 'second_option_interview_schedule' && !props.row.second_option_ministry"
        >
          -
        </span>
        <span v-else-if="props.column.field == 'action'">
          <b-button
            v-if="props.row.servolution_registration_status_id === 1 || props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 12 || props.row.servolution_registration_status_id === 14"
            v-b-tooltip.hover.bottom="'Edit'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'edit-servolution-participant', params: { id: props.row.id } }"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
            v-if="props.row.servolution_registration_status_id === 7 || props.row.servolution_registration_status_id === 8"
            v-b-tooltip.hover.bottom="'View'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'servolution-assessment', params: { id: props.row.id } }"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-if="props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 4 || props.row.servolution_registration_status_id === 6 || props.row.servolution_registration_status_id === 34"
            v-b-tooltip.hover.bottom="'Assessment'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            :to="{ name: 'servolution-assessment', params: { id: props.row.id } }"
          >
            <feather-icon icon="ClipboardIcon" />
          </b-button>
          <b-button
            v-if="props.row.servolution_registration_status_id === 1 || props.row.servolution_registration_status_id === 2 || props.row.servolution_registration_status_id === 12 || props.row.servolution_registration_status_id === 14"
            v-b-tooltip.hover.bottom="'Delete'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon"
            style="margin-right: 2px; margin-bottom: 2px;"
            @click="deleteParticipant(props.row.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
        <span v-else-if="props.column.field == 'second_option_ministry'">
          {{ props.row.second_option_ministry ? props.row.second_option_ministry : '-' }}
        </span>
        <span v-else-if="props.column.field == 'status'">
          <b-badge
            :variant="props.row.status_style"
          >
            {{ props.row.status_name }}
          </b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BAvatar, BFormGroup, BFormInput, BCard, BButton, VBTooltip, BBadge,
} from 'bootstrap-vue'
import Loading from 'vue-loading-overlay'
import Ripple from 'vue-ripple-directive'
import CryptoJS from 'crypto-js'
import * as moment from 'moment'
// import excel from 'vue-excel-export'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueGoodTable } from 'vue-good-table'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BAvatar,
    BFormGroup,
    BFormInput,
    BCard,
    VueGoodTable,
    BButton,
    BBadge,
    // BDropdown,
    // BDropdownItem,
    Loading,
    // excel,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      moment,
      isLoading: true,
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Age',
          field: 'age',
        },
        {
          label: 'Gender',
          field: 'gender',
        },
        {
          label: 'CG Category',
          field: 'connect_group_category',
        },
        {
          label: 'First Ministry',
          field: 'first_option_ministry',
        },
        {
          label: 'First Ministry Schedule',
          field: 'first_option_audition_schedule',
        },
        {
          label: 'Second Ministry',
          field: 'second_option_ministry',
        },
        {
          label: 'Second Ministry Schedule',
          field: 'second_option_interview_schedule',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      json_fields: {
        'Complete name': 'name',
        City: 'city',
        Telephone: 'phone.mobile',
        'Telephone 2': {
          field: 'phone.landline',
          callback: value => `Landline Phone - ${value}`,
        },
      },
      exportColumns: [
        {
          label: 'NIJ',
          field: 'nij',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Photo',
          field: 'photo',
        },
        {
          label: 'Age',
          field: 'age',
        },
        {
          label: 'Gender',
          field: 'gender',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'CG Category',
          field: 'connect_group_category',
        },
        {
          label: 'CG',
          field: 'connect_group_name',
        },
        {
          label: 'CG Level',
          field: 'connect_group_level',
        },
        {
          label: 'GMS',
          field: 'church_name',
        },
        {
          label: 'Attending Service at',
          field: 'service_category',
        },
        {
          label: 'First Division',
          field: 'first_option_ministry',
        },
        {
          label: 'First Option Audition Room',
          field: 'first_option_audition_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'First Option Audition Schedule',
          field: 'first_option_audition_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'First Option Interview Room',
          field: 'first_option_interview_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'First Option Interview Schedule',
          field: 'first_option_interview_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'Second Division',
          field: 'second_option_ministry',
        },
        {
          label: 'Second Option Interview Room',
          field: 'second_option_interview_room',
          dataFormat: this.formatRoom,
        },
        {
          label: 'Second Option Interview Schedule',
          field: 'second_option_interview_schedule',
          dataFormat: this.formatSchedule,
        },
        {
          label: 'Status',
          field: 'status_name',
        },
      ],
      exportData: [],
    }
  },
  mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    const divisions = userData[0].divisions.map(elem => elem).join(',')

    // Get the data
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrations/${this.$route.params.id}/${divisions}`)
      .then(response => {
        console.log(response.data)
        this.isLoading = false
        this.rows = response.data
        this.exportData = response.data
    })
  },
  methods: {
    priceFormat(value) {
      return `$ ${value}`
    },
    exportParticipants() {
      
    },
    formatRoom(value) {
      if (value) {
        return value
      }

      return '-'
    },
    formatSchedule(value) {
      if (value) {
        return moment(value).format('dddd, DD MMMM YYYY HH:mm')
      }

      return '-'
    },
    deleteParticipant(id) {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      // Check if the user has access
      if (!this.$can('delete', 'servolution_participants')) {
        this.$swal({
          icon: 'error',
          title: 'Forbidden!',
          text: 'You are not authorized to do this action.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          // Show loading
          this.isLoading = true

          if (result.value) {
            // Delete participant
            this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/${id}`, { updatedAt: new Date(), deletedAt: new Date() })
              .then(() => {
              // Hide loading
              this.isLoading = false

              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'This participant has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              const participantIndex = this.rows.findIndex(participant => participant.id === id)
              this.rows.splice(participantIndex, 1)
            })
          } else {
            // Hide loading
            this.isLoading = false
          }
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>